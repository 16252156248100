@use "sass:math"
//LESS variable matches (should always match corresponding overrides in overrides.less)

$screenxs: 480px
$screensm: 576px
$screenmd: 768px
$screenlg: 992px
$screenxl: 1200px
$screenxxl: 1600px
$marginvalue: 12px
$branddefault: #3d5a80
$brandaccent: #767676
$brandfooter: #767676
$white: #ffffff
$midgrey: #b4b4b4
$approve: #3f9531
$reject: #ff6b00

//Internal variables

$siderwidth: 200px
$sidercollapsedwidthmobile: 40px
$logowidth: 200px
$navmenucolor: transparent
$navhovercolor: white
$footerheight: $marginvalue * 2
$homebannerheight: 0px
$homebannerwidth: 0px

=shadow($shadowlevel)
	@if $shadowlevel == 1
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
	@if $shadowlevel == 2
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
	@if $shadowlevel == 3
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)
	@if $shadowlevel == 4
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)
	@if $shadowlevel == 5
		box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)
	@if $shadowlevel == left2
		box-shadow: inset 5px 0 7px -5px rgba(0, 0, 0, 0.5), 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)


@mixin scrollbars($size)
	// For Google Chrome
	&::-webkit-scrollbar
		width: $size
		height: $size

	&::-webkit-scrollbar-thumb
		background: $branddefault


	&::-webkit-scrollbar-track
		background: lighten($brandaccent, 30%)

@import "client_variables"


//Page structure

html, body, #root
	height: 100%
	width: 100%
	overflow-x: hidden

.ant-layout
	height: 100%
	width: 100%

.nonheader_wrapper
	display: flex
	flex-direction: column
	height: 100%
	width: 100%
	overflow-y: auto

.ts_content
	padding: ($marginvalue * 2) $marginvalue ($marginvalue * 3) $marginvalue
	overflow-x: auto

.ts_full_width
	width: 100%
	
.ts_width_fit_content
	width: fit-content

.ts_select_small
	max-width: 120px

.ts_select_medium
	min-width: 150px
	
.ts_order_header_settings
	max-width: 400px
	
.ts_order_header_attachments
	max-width: 300px
	height: 714px
	
.ts_order_header_projectinfo
	width: 300px
	
.ts-file-manager-uploader
	padding: calc($marginvalue / 2)
	cursor: pointer

//Header

.header_logo
	display: block
	width: 100px


.ant-layout-header
	+shadow(1)


.ts_header
	padding-left: 24px
	color: #ffffff
	background-color: $branddefault !important

	.ts_nav_collapse_icon
		font-size: 1.8em
		padding-top: 12px
		margin-right: 28px

	.ts_header_title
		color: #ffffff
		margin-left: 30px !important
		white-space: nowrap
		
	.ant-row
		flex-wrap: nowrap
		
		.ant-col:last-child
			white-space: nowrap


.ts_sider_container
	@include scrollbars(7px)
	background-color: darken($branddefault, 20%) !important
	max-height: 100vh
	overflow: auto


.nav_menu
	background-color: darken($branddefault, 20%) !important

	.ant-menu-item, .ant-menu-submenu
		background-color: $navmenucolor !important


.ts_flex_row
	display: flex
	flex-direction: row


//App-specific

.ts_portal_card
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 4px rgba(0, 0, 0, 0.03), 0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.05)

.ts_home_card
	border-top: 2px solid $branddefault
	background-color: #ffffff
	padding: 16px
	margin: 6px
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.05)
		min-height: 150px

.ts_address_card
	border-top: 2px solid $branddefault
	background-color: #ffffff
	padding: 16px
	margin: 16px
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.05)
	min-height: 150px

.ts_card_edit_button
	float: right

.ts_card_add_button
	margin-left: 10px

.ts_portal_contact_form
	width: 100%

.ts_home_content_container
	max-width: 1300px
	margin: 0 auto

.ts_home_menu
	border-right: none

.login_form
	max-width: 400px

.login_form_forgot
	float: right

.login_alert
	margin-bottom: 16px

.ts_margin_sm
	margin-bottom: 6px

.ts_margin_bottom
	margin-bottom: 16px

.ts_margin_top
	margin-top: 16px

.ts_margin_top_small
	margin-top: 8px

.ts_margin_left
	margin-left: 16px

.ts_form_margin
	margin-top: 16px

.ts_form_container
	width: 60%

// pads AntD form components to bring them inline with a form element having a vertical order_summary_labels
.ts_form_padding_top
	padding-top: 30px !important

.ts_form_padding_left
	padding-left: 16px !important

.ts_disabled_alternate
	&:disabled
		color: #272727 !important

.ts_pdf_modal
	width: 95% !important
	height: 95vh !important

	> .ant-modal-content
		height: 100%

		> .ant-modal-body
			height: calc(100% - 130px)

.ts_pdf
	width: 100%
	height: 100%

.ts_card_dark_border
	border-color: #b4b4b4

.ts_pre_wrap
	white-space: pre-wrap


.ts_approvals_button_margin
	margin-right: $marginvalue


.ts_approvals_approve_button
	background-color: #3f9531
	border-color: #3f9531

	&:focus
		background-color: #3f9531
		border-color: #3f9531

.ts_approvals_reject_button
	background-color: #ff6b00
	border-color: #ff6b00

	&:focus
		background-color: #ff6b00
		border-color: #ff6b00

.ts_approvals_comments_button
	font-size: 2em

.ts_approvals_comments_button_active
	color: $branddefault


.ts_content_container
	margin: 8px 24px


.ts_bp_header_sub_title
	margin-top: 7px !important
	margin-left: 10px


.ts_dx_grid_search_panel
	position: absolute

.ts_dev_express_table_toolbar
	width: 100%
	display: flex
	justify-content: flex-start

.ts_large_checkbox
	height: 25px
	width: 25px
	margin-top: 5px

.model_sku_grid_checkbox
	align-self: center
	margin: 8px
	height: 25px
	width: 50px !important
	padding-right: 15px !important


.order_summary_labels
	margin-left: 800px
	min-height: 32px

.order_summary_values
	margin-left: 800px
	min-height: 33px

.ts_set_width
	min-width: 120px
	max-width: 120px


//Footer

.ant-layout-footer
	padding-top: $marginvalue
	padding-bottom: $marginvalue
	min-height: $footerheight
	display: flex
	justify-content: center
	align-items: center

.ts_footer
	background: $brandfooter !important
	color: #ffffff !important

.ts_padding
	padding: 4px

.ts_padding_md
	padding: 6px

.ts_margin_left
	margin-left: 8px

.ts_margin_right
	margin-right: 8px

.ts_icon_danger
	color: red

.ts_icon_success
	color: #3f9531

.ts_fade
	opacity: 0.5

.ts_icon_large
	font-size: 2em

.ts_user_management_group_permissions_buttons
	margin-left: 425px

	button
		margin-right: 16px
		margin-top: 8px

.ts_table_header_row
	background-color: #FAFAFA

.ts_approval_detail_table
	width: 100%

	tr
		border-bottom: 1px solid #E0E0E0

	td, th
		padding: 12px
		text-align: left


.ts_notification_container
	margin-bottom: 12px

.ts_notification_popover
	margin-bottom: 12px
	max-height: 300px
	height: 300px
	overflow: auto
	width: 500px

.ts-help-button
	font-size: 90%
	position: relative
	top: -0.4em
	margin-left: 4px
	margin-right: 4px
	color: $branddefault

.ts-text-right
	text-align: right

.ts-text-left
	text-align: left

.ts-width-70
	width: 70px

.ts-width-100
	width: 100px

.ts-width-150
	width: 150px

.ts-width-200
	width: 200px

.ts-width-250
	width: 250px

.ts-width-300
	width: 300px

.ts-width-400
	width: 400px

.ts-width-500
	width: 500px

.ts-width-600
	width: 600px

.ts-width-700
	width: 700px

.ts-padding-left-16
	padding-left: 16px

.ts-padding-right-16
	padding-right: 16px

.ts-no-border-ant-select
	.ant-select-selector
		border: none !important

.ts-file-manager-thin-border
	border-radius: 16px
	border: 1px #c2c2c2 solid

.ts-file-manager-file-container
	padding: 8px 0
	border-color: #dedede
	margin-bottom: 8px

.ts-file-manager-text
	padding: 20px 5px
	font-size: 11px


.ts-file-manager-dashed-container
	border: 2px #c2c2c2 dashed
	border-radius: 16px

.ts-file-manager-uploader
	padding: 20px 10px
	height: 100px
	min-width: 150px
	max-width: 600px
	text-align: center
	span 
		font-size: 10px
	

.ts-order-entry-sider
	background-color: #F0F2F5
	//overflow: auto
	//height: calc(100vh - 160px)
	
.ts_order_header
	margin: 0 !important
	display: block
	font-size: 0px 
	background-color: white
	
	> div
		font-size: 0px
		
%common-styles
	background-color: white
	padding: calc($marginvalue/2)
	height: min-content

	.ant-layout-content
		font-size: 0px

	.ant-card
		border: 0px solid white

.ts_order_page_content
	@extend %common-styles
	min-width: 1200px

.ts_po_page_content
	@extend %common-styles

.ts-list-divider
	border-bottom: 1px #ececec solid
	padding: 4px

.ts-orders-line-children-container
	background-color: #f3eae1
	border: 1px #d7c4b5 solid
	padding: 4px



.ts-orders-child-line-table
	margin-top: 16px
	margin-bottom: 16px
	max-width: calc(100% - 12px)
	& div.ant-table
		margin-left: 0 !important
	& th.ant-table-cell
		background-color: #ded5cd
	& td.ant-table-cell
		background-color: #efe6dd

.ts-production-suggested-doc-container
	margin: 16px
	padding: 8px
	background-color: #f6f0ed
	border: 1px #decec7 solid

.ts-production-existing-doc-container
	margin: 16px
	padding: 8px
	background-color: #edf4f6
	border: 1px #c7d7de solid

.ts-image-preview
	max-width: 100%
	
.ts_tablescroll, .ts_portal_card
	> .ant-card-body
		overflow-x: auto
		
		.ant-space-item
			padding-right: 2 * $marginvalue

.ts_add_bottommargin
	margin-bottom: 30px

.ts_portal_card
	.ant-table-content
		margin-right: -2 * $marginvalue
	table
		padding-right: 2 * $marginvalue

.ts_tablescroll
	.ant-space-item
			margin-right: -2 * $marginvalue

.ts_hide_scroll
	overflow: hidden

.sub-table
	background-color: #EDF4F6

//Media Queries
@media screen and (max-width: $screenxl)
	.ts_content
		padding: ($marginvalue) ($marginvalue * 0.5) ($marginvalue * 1.5) ($marginvalue * 0.5)


@media screen and (max-width: $screenmd)
	.ant-layout-header
		padding: 0 ($marginvalue * 2)
		
@import "client"
