//Options: TS | GENERIC | ALLPRO | BB
$mode: BB

@if $mode==TS
	$branddefault: black
	$brandaccent: darken($branddefault,30%)
	$brandfooter: lighten($branddefault,30%)
	$homebannerheight: 0px
	$homebannerwidth: 0px
		
@if $mode==GENERIC

@if $mode==ALLPRO
	$branddefault: #CD2735
	$brandaccent: darken($branddefault,30%)
	$brandfooter: darken($branddefault,30%)

@if $mode==BB
	$branddefault: #36404d
	$brandaccent: darken($branddefault,30%)
	$brandfooter: darken($branddefault,30%)
	$homebannerheight: 400px
	$homebannerwidth: 1080px