@if $mode==TS

	.header_logo
		width: 100px
		max-height: 48px
		height: 48px
		background-image: url(/assets/images/default/logo.svg)
		
	.nav_menu
		background-color: lighten($branddefault, 20%) !important
		
	.ts_sider_container
		@include scrollbars(7px)
		background-color: lighten($branddefault, 20%) !important
		
	.sider_logo
		margin-bottom: $marginvalue * 1
		border: 0 solid rgba(0,0,0,0) !important
		
@if $mode==GENERIC
	.header_logo
		width: 0
		max-height: 48px
		height: 48px
		background-image: none
		
	.ts_header_title
		color: $white
		margin-left: 30px !important
		
	.sider_logo
		background-image: url(/assets/images/default/sap_white.png)
		border: ($marginvalue * 1.5) solid rgba(0,0,0,0)
		
	.ts_header .ts_header_title
		margin-left: 0 !important
		
	.ant-menu-dark .ant-menu-inline.ant-menu-sub
		background: darken($branddefault, 25%)
	
@if $mode==ALLPRO

	.header_logo
		width: 100px
		max-height: 46px
		height: 46px
		background-image: url(/assets/images/client/APlogotype.png)
		margin-top: 3px
		
	.ts_header_title
		color: $white
		margin-left: 30px !important
		
	.sider_logo
		background-image: url(/assets/images/client/APwhite.png)
		margin-bottom: $marginvalue * 1.5
		border: math.div($marginvalue, 2) solid rgba(0,0,0,0)
		
	.ts_sider_container
		@include scrollbars(7px)
		background-color: darken($branddefault, 14%) !important
		
	.nav_menu
		background-color: darken($branddefault, 14%) !important
		
@if $mode==BB

	.header_logo
		width: auto
		max-height: 45px
		margin-top: 3px
		height: 38px
		margin-top: 4px
		
	.ts_header_title
		color: $white
		margin-left: 30px !important
		
	.sider_logo
		margin-bottom: $marginvalue * 1.5
		border: math.div($marginvalue, 2) solid rgba(0,0,0,0)
		
	.ts_sider_container
		@include scrollbars(7px)
		background-color: darken($branddefault, 14%) !important
		
	.nav_menu
		background-color: darken($branddefault, 24%) !important
		
	.frontpage_marketing_banner_container
		img
			max-width: $homebannerwidth

	